import { IconWrapperProps } from '../IconWrapper';

type GitHubIconProps = Pick<IconWrapperProps, 'className'>;

const GitHubIcon = (props: GitHubIconProps) => (
  <div className={`inline-block h-[0.8em] w-[0.8em] ${props.className}`}>
    <div className="content-[''] -translate-x-[0.25em] rotate-45 h-full w-full border-r-2 border-t-2 border-current"></div>
  </div>
);

export default GitHubIcon;
